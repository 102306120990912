import React from "react";
import Layout from "../../components/layout";
import { article } from "../../styles/modules/post_template.module.css";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import { ZodiacSign } from "../../components/ZodiacSign";

const formatDate = (dateISOString, region, options) => {
  const date = new Date(dateISOString);

  return date.toLocaleDateString(region, options);
};

function Page() {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const data = {
    week: "01",
    zodiacSigns: [
      {
        signName: "aries",
        frenchName: "Bélier",
        colorName: "Vert Émeraude",
        colorHex: "#ccddaa",
        description:
          "Aujourd'hui, la couleur vert émeraude est le choix chanceux pour le Bélier, car elle symbolise le renouvellement et l'énergie positive. Cette teinte vibrante vous aidera à relever les défis avec détermination et attirera la bonne chance dans vos entreprises.",
      },
      {
        signName: "taurus",
        frenchName: "Taureau",
        colorName: "Bleu Saphir",
        colorHex: "#336699",
        description:
          "Pour le Taureau, le bleu saphir est la couleur de la chance aujourd'hui. Il représente la stabilité et la confiance, vous offrant une aura de tranquillité qui vous permettra de prendre des décisions éclairées et d'attirer la prospérité dans vos projets.",
      },
      {
        signName: "gemini",
        frenchName: "Gémeaux",
        colorName: "Jaune Brillant",
        colorHex: "#ffcc00",
        description:
          "Les Gémeaux trouveront la chance dans le jaune brillant aujourd'hui. Cette couleur symbolise la créativité et la communication, vous fournissant l'énergie nécessaire pour exprimer vos idées de manière claire et efficace, vous conduisant ainsi vers le succès.",
      },
      {
        signName: "cancer",
        frenchName: "Cancer",
        colorName: "Argent Lumineux",
        colorHex: "#c0c0c0",
        description:
          "Aujourd'hui, la couleur argent lumineux est indiquée pour le Cancer. Elle représente l'intuition et la protection, vous offrant la tranquillité émotionnelle nécessaire. Cette teinte argentée agira comme un bouclier positif, attirant des vibrations positives autour de vous.",
      },
      {
        signName: "leo",
        frenchName: "Lion",
        colorName: "Orange Chaud",
        colorHex: "#ff9900",
        description:
          "Le Lion trouve sa couleur de chance dans l'orange chaud aujourd'hui. Cette teinte représente la vitalité et la confiance en soi, vous incitant à vous démarquer et à briller dans n'importe quelle situation. Profitez de cette énergie pour atteindre vos objectifs avec succès.",
      },
      {
        signName: "virgo",
        frenchName: "Vierge",
        colorName: "Marron Terre",
        colorHex: "#8b4513",
        description:
          "Pour la Vierge, le marron terre est la couleur chanceuse aujourd'hui. Il symbolise la stabilité et la connexion avec la nature, vous fournissant une base solide pour aborder n'importe quel défi. Avec cette couleur, vous trouverez l'équilibre et l'harmonie dans vos actions.",
      },
      {
        signName: "libra",
        frenchName: "Balance",
        colorName: "Rose Doux",
        colorHex: "#ffcccc",
        description:
          "Balance, aujourd'hui votre couleur de chance est le rose doux. Cette teinte représente l'amour et l'harmonie, créant un environnement propice aux relations positives. Utilisez cette couleur pour renforcer les liens affectifs et attirer la paix dans votre vie.",
      },
      {
        signName: "scorpio",
        frenchName: "Scorpion",
        colorName: "Rouge Intense",
        colorHex: "#ff0000",
        description:
          "Les Scorpions trouveront leur chance dans le rouge intense aujourd'hui. Cette couleur représente la passion et la détermination, vous incitant à relever les défis avec courage. Utilisez cette teinte pour attirer l'énergie nécessaire et atteindre vos objectifs avec succès.",
      },
      {
        signName: "sagittarius",
        frenchName: "Sagittaire",
        colorName: "Pourpre Royal",
        colorHex: "#800080",
        description:
          "Sagittaire, aujourd'hui le pourpre royal est votre couleur de chance. Il symbolise la sagesse et l'expansion, vous offrant l'inspiration nécessaire pour explorer de nouvelles possibilités. Utilisez cette teinte pour élargir vos horizons et poursuivre vos rêves avec confiance.",
      },
      {
        signName: "capricorn",
        frenchName: "Capricorne",
        colorName: "Gris Clair",
        colorHex: "#d3d3d3",
        description:
          "Pour le Capricorne, le gris clair est la couleur chanceuse aujourd'hui. Il représente la stabilité et la praticité, vous fournissant une base solide pour prendre des décisions éclairées. Utilisez cette teinte pour aborder vos responsabilités avec détermination.",
      },
      {
        signName: "aquarius",
        frenchName: "Verseau",
        colorName: "Turquoise Rafraîchissante",
        colorHex: "#00ced1",
        description:
          "Verseau, trouvez votre chance dans la turquoise rafraîchissante aujourd'hui. Cette teinte symbolise l'innovation et la liberté, vous offrant l'inspiration nécessaire pour suivre vos instincts créatifs. Utilisez cette couleur pour briser les barrières et expérimenter le succès dans de nouvelles entreprises.",
      },
      {
        signName: "pisces",
        frenchName: "Poissons",
        colorName: "Lavande Paisible",
        colorHex: "#e6e6fa",
        description:
          "Poissons, aujourd'hui la lavande paisible est votre couleur de chance. Elle représente la paix et l'intuition, créant une atmosphère sereine autour de vous. Utilisez cette teinte pour vous connecter avec votre intuition et prendre des décisions qui vous guideront vers l'harmonie et le bien-être.",
      },
    ],
  };

  return (
    <Layout locale="fr">
      <article className={article}>
        <h1>Couleur de la chance pour aujourd'hui {textDate}</h1>
        <p>
          Bienvenue à l'
          <strong>
            horoscope des couleurs de la chance pour aujourd'hui !
          </strong>{" "}
          Découvrez comment les tons vibrants peuvent influencer positivement
          votre journée selon votre signe du zodiaque. Rejoignez-nous alors que
          nous explorons les couleurs chanceuses qui vous entoureront et vous
          propulseront vers la prospérité au cours de cette semaine.
        </p>
        <h2>Couleurs de la chance selon votre signe :</h2>
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "1rem",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
            gap: "1rem",
          }}
        >
          {data.zodiacSigns.map(sign => (
            <li>
              <article
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "140px 1fr",
                  boxShadow: "0 0 3px 0 gray",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: sign.colorHex,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "1rem",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <ZodiacSign
                    sign={sign.signName}
                    color={darkOrLightColor(sign.colorHex)}
                    width={44}
                    height={44}
                  />
                  <span
                    style={{
                      fontSize: "1.2rem",
                      color: darkOrLightColor(sign.colorHex),
                    }}
                  >
                    {sign.colorHex}
                  </span>
                </div>
                <div style={{ padding: ".8rem" }}>
                  <h3
                    style={{
                      fontSize: "1.4rem",
                      margin: "0 0",
                      padding: "0.5rem 0",
                      lineHeight: 1.3,
                    }}
                  >
                    La couleur pour {sign.spanishName} aujourd'hui est{" "}
                    {sign.colorName}
                  </h3>
                  <p
                    style={{
                      fontSize: ".9rem",
                      margin: ".5rem 0",
                      lineHeight: 1.5,
                    }}
                  >
                    {sign.description}
                  </p>
                </div>
              </article>
            </li>
          ))}
        </ul>
        <p>
          Profitez pleinement du pouvoir des couleurs et laissez-les guider
          votre journée vers l'harmonie et le succès. N'oubliez pas d'emporter
          avec vous l'énergie positive que chaque teinte apporte à votre signe
          du zodiaque. Que ces couleurs de la chance illuminent votre journée et
          vous apportent la fortune que vous recherchez ! À la prochaine
          livraison de couleurs astrales ! 🌈✨
        </p>
      </article>
    </Layout>
  );
}

export const Head = () => {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <title>Couleurs de chance de cette semaine 《Signe Zodiacal》</title>
      <meta
        name="description"
        content={`Explorez l'horoscope des couleurs pour aujourd'hui ${textDate} et découvrez comment votre signe du zodiaque peut briller avec les teintes chanceuses`}
      />
    </>
  );
};

export default Page;
